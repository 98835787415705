<template>
  <div data-cy="page-mentor-eval-success" aria-labelledby="mentor evaluation success page" ref="mentorEvaluationSuccessPage">
    <div class="flex flex-col justify-center items-center box-style pb-20">
      <div
        class="text-3xl text-dark-purple font-bold pt-12 pb-4"
        data-cy="add-success-header"
      >
        Success!
      </div>
      <div class="text-xl">
        Your end-of-year survey has been successfully sent.
      </div>
      <div class="pb-8 text-xl">
        Thanks for sharing feeback about your mentor with us.
      </div>
    </div>
  </div>
</template>

<script>
// TODO: THIS PAGE SHOULD ONLY BE ACCESSIBLE AFTER ADDING A LOG
// TODO: ADD ROUTE GUARD TO THIS PAGE... Maybe? Route guard should include a check for JWT
export default {
  name: "MentorEvalSuccess",
    mounted() {
    this.$refs.mentorEvaluationSuccessPage.focus(); 
  },
};
</script>

<style scoped></style>
